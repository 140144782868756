import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "CART"

const initialState = {
    cart: [],
    loadCartItems: false,
    prewUrlIsCheckout: false
}

export const addToCartThunk = createAsyncThunk(
    `${name}/add-to-cart`,
    async (params, {rejectWithValue}) => {
        try {
            return await logger({
                method: 'POST',
                url: `${API_URL}/add-to-cart`,
                body: {...params}
            })
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const getCartThunk = createAsyncThunk(
  `${name}/get-cart`,
  async (params, {rejectWithValue}) => {
      try {
          return await logger({
              method: 'POST',
              url: `${API_URL}/get-cart`,
              body: {...params}
          })
      } catch (err) {
          return rejectWithValue(err)
      }
  }
)

export const deleteCartThunk = createAsyncThunk(
    `${name}/delete-cart-item`,
    async (params, {rejectWithValue}) => {
        try {
            return await logger({
                method: 'POST',
                url: `${API_URL}/delete-cart-item`,
                body: {...params}
            })
        } catch (err) {
            return rejectWithValue(err)
        }
    }
  )

  export const editQtyItemCartThunk = createAsyncThunk(
    `${name}/edit-cart-item-qty`,
    async (params, {rejectWithValue}) => {
        try {
            return await logger({
                method: 'POST',
                url: `${API_URL}/edit-cart-item-qty`,
                body: {...params}
            })
        } catch (err) {
            return rejectWithValue(err)
        }
    }
  )

const cartSlice = createSlice({
    name,
    initialState,
    reducers:{
        setPrewUrlIsCheckout : (state, { payload }) => {
          state.prewUrlIsCheckout = true;
        },
      },
    extraReducers: (builder) => {
      builder
        .addCase(addToCartThunk.fulfilled, (state, action) => {
          state.cart = action.payload
          state.loadCartItems = true
          !localStorage.getItem("token") && localStorage.setItem("token", action.payload.cartinfo.token)
        })
        .addCase(getCartThunk.fulfilled, (state, action) => {
          state.cart = action.payload
          state.loadCartItems = true
        })
        .addCase(deleteCartThunk.fulfilled, (state, action) => {
          state.cart = action.payload
          state.loadCartItems = true
        })
        .addCase(editQtyItemCartThunk.fulfilled, (state, action) => {
          state.cart = action.payload
          state.loadCartItems = true
        })
    },
})

export const { setPrewUrlIsCheckout } = cartSlice.actions


export const selectCartData = (state) => state.cart
export const selectCartLoaded = (state) => state.loadCartItems
export const selectPrewUrlIsCheckout = (state) => state.cart.prewUrlIsCheckout



export default cartSlice.reducer;
