import Link from "next/link";
import React from "react";
import LogoPurple from "../../public/icons/resources/LogoPurple";

export default function Header_login_reg() {
  return (
    <>
      <div className="header_login_reg">
        <div>
          <Link href="/">
            <LogoPurple />
          </Link>
        </div>
      </div>
    </>
  );
}
