import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "FAQs";

const initialState = {
  FAQsData: {},
  buyingHelpData: '',
};

export const FAQsThunk = createAsyncThunk(`${name}/faqs`, async () =>
  logger({
    method: "GET",
    url: `${API_URL}/faqs`,
  })
);

export const buyingHelpThunk = createAsyncThunk(
  `${name}/buying-help`,
  async (values ,{rejectWithValue}) =>{
    try {
      return await logger({
        method: "POST",
        url: `${API_URL}/buying-help`,
        body: { ...values },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  }
);

const FAQsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(FAQsThunk.fulfilled, (state, action) => {
      state.FAQsData = action.payload;
    });
    builder.addCase(buyingHelpThunk.fulfilled, (state, action) => {
      state.buyingHelpData = action.payload.data[0];
    });

    buyingHelpThunk
  },
});

export const selectFAQsData = (state) => state.FAQs.FAQsData;
export const selectBuyingHelpData = (state) => state.FAQs.buyingHelpData;

export default FAQsSlice.reducer;
