import React from "react";

export default function BtnArrowAnimation() {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </mask>
      <g mask="url(#a)" fill="#fff">
        <path d="M14.676 6.7l4.6 4.6c.1.1.17.208.212.324a1.1 1.1 0 01.063.375c0 .134-.021.259-.063.375a.872.872 0 01-.212.325l-4.6 4.6a.948.948 0 01-.7.275.948.948 0 01-.7-.275.948.948 0 01-.275-.7c0-.283.091-.516.275-.7l3.9-3.9-3.9-3.9A.948.948 0 0113 7.4c0-.283.091-.516.275-.7a.948.948 0 01.7-.275c.283 0 .516.092.7.275z" />
        <rect x={4} y={11} width={14} height={2} rx={1} />
      </g>
    </svg>
  );
}
