import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";
import Cookies from "js-cookie";

const name = "USER";

const initialState = {
  userData: '',
  errorMessage: "",
  creditCards: [],
};

export const userInfoThunk = createAsyncThunk(
  `${name}/customer/info`,
  async (values ,{rejectWithValue}) =>{
    try {
      return await logger({
        method: "POST",
        url: `${API_URL}/customer/info`,
        body: { ...values },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  }
);

const userSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(userInfoThunk.fulfilled, (state, action) => {
        state.userData = action.payload.data[0];
        localStorage.setItem("isLogedIn", String(true))
      })
      .addCase(userInfoThunk.rejected, (state, action) => {
        Cookies.remove("authorized")
        localStorage.removeItem("isLogedIn");
        state.errorMessage = action.payload;
        state.userData = '';
      });
  },
});

export const selectUserInfo = (state) => state.user.userData;
export const selectUserInfoErr = (state) => state.user.errorMessage;


export default userSlice.reducer;
