import Layout from '../components/layout'
import store from '../redux/store'
import { Provider } from 'react-redux'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '../styles/globals.scss'
import '../styles/base/headerStyle.scss'
import '../styles/base/footerStyle.scss'
import '../styles/buyForMeStyle.scss'
import '../styles/sliders/brandsSliderStyle.scss'
import '../styles/sliders/topProductsSliderStyle.scss'
import '../styles/sliders/learnMoreStyle.scss'
import '../styles/featuresStyle.scss'
import '../styles/pricingStyle.scss'
import '../styles/FAQ_ComponentStyle.scss'
import '../styles/moreEffectiveStyle.scss'
import '../styles/sliders/partnerOperatorstyle.scss'
import '../styles/base/details/mobileMenuStyle.scss'
import '../styles/base/details/RegistredHeaderDetailsStyle.scss'
import '../styles/base/details/searchStyle.scss'
import '../styles/pages/pageShopsStyle.scss'
import '../styles/tabsStyle.scss'
import '../styles/multipleProductBarStyle.scss'
import '../styles/shopsFiltersStyle.scss'
import '../styles/pages/pageDealsStyle.scss'
import '../styles/pages/pageBlogStyle.scss'
import '../styles/pages/pageblogDetailsStyle.scss'
import '../styles/sliders/moreBlogsStyle.scss'
import '../styles/contactUsSectionStyle.scss'
import '../styles/pages/contactUsStyle.scss'
import '../public/icons/icomoon/style.css'
import '../styles/base/header_login_reg_style.scss'
import '../styles/pages/loginPageStyle.scss'
import '../styles/base/FooterWithoutLogoStyle.scss'
import '../styles/pages/forgotPasswordStyle.scss'
import '../styles/pages/createNewPasswordStyle.scss'
import '../styles/pages/registrationPageStyle.scss'
import '../styles/pages/telcellRedirectStyle.scss'
import '../styles/pages/account/accauntMenuStyle.scss'
import '../styles/pages/account/editProfileStyle.scss'
import '../styles/pages/account/accoutnChangePasswordStyle.scss'
import '../styles/pages/account/PaymentMethodsStyle.scss'
import '../styles/pages/account/accountNotificationsStyle.scss'
import '../styles/pages/account/details/confirmRemovalPopUpStyle.scss'
import '../styles/pages/account/details/notificationPopUpStyle.scss'
import '../styles/pages/account/details/menuPopUpMenusStyle.scss'
import '../styles/pages/account/details/closeAccountPopUpStyle.scss'
import '../styles/pages/faqPageStyle.scss'
import '../styles/pages/termsStyle.scss'
import '../styles/pages/transactionsStyle.scss'
import '../styles/pages/notificationsStyle.scss'
import '../styles/pages/emailVerifyStyle.scss'
import '../styles/pages/ordered_products/orderedProductsStyle.scss'
import '../styles/pages/ordered_products/comfirmedProductsStyle.scss'
import '../styles/pages/ordered_products/details/EmptyConfirmedOrderStyle.scss'
import '../styles/pages/ordered_products/details/comfirmedItemStyle.scss'
import '../styles/pages/ordered_products/details/orderStateStyle.scss'
import '../styles/pages/ordered_products/details/canceledItemStyle.scss'
import '../styles/pages/ordered_products/details/removalSavedItemsPopUpStyle.scss'
import '../styles/pages/place_order/placeOrderStyle.scss'
import '../styles/pages/place_order/submitOrderStyle.scss'
import '../styles/pages/ErrorStyle.scss'
import '../styles/pages/orderStyle.scss'
import '../styles/pages/checkout/checkoutStyle.scss'
import '../styles/pages/checkout/checkoutItemStyle.scss'
import '../styles/pages/checkout/emptyBagStyle.scss'
import '../styles/pages/checkout/checkoutPlaceOrderStyle.scss'
import '../styles/pages/fill_balance_style/fillBalanceStyle.scss'
import '../styles/pages/fill_balance_style/fillBalanceCardFillSectionStyle.scss'
import '../styles/pages/fill_balance_style/inputPhoneNumberFillBalanceStyle.scss'
import 'react-phone-number-input/style.css'
import '../styles/loaderStyle.scss'
import '../styles/paginationStyle.scss'
import '../styles/praductStyle.scss'
import '../i18n'
import { appWithTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { userInfoThunk } from '../redux/slices/userSlice'
import { logInThunk } from '../redux/slices/authenticationSlice'
import { newNotificationsThunk } from '../redux/slices/notificationsSlice'
import Head from 'next/head'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { dispatch } = store
  const [domLoaded, setDomLoaded] = useState(false)

  const site = 'https://arformefront.ayotech.am'
  const pathname = useRouter().asPath
  const canonicalURL = site + pathname

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  useEffect(() => {
    if (router.query.access_token && router.pathname !== '/auth/sign-in') {
      Cookies.set('authorized', router.query.access_token)
      Router.push('/')
    }
  }, [router.query])

  useEffect(() => {
    if (Cookies.get('authorized') && router.pathname !== '/auth/sign-in') {
      dispatch(userInfoThunk())
    } else {
      localStorage.removeItem('isLogedIn')
    }
  }, [Cookies.get('authorized')])

  // setInterval(()=>{
  //    dispatch(newNotificationsThunk())
  //  }, 1000000)

  return (
    <Provider store={store}>
      {domLoaded && (
        <>
          <Head>
            {/* <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="canonical" href={canonicalURL} key="canonical" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-capable" content="yes" /> */}

            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/images/logo-title.png"
            />
            <link rel="canonical" href={canonicalURL} />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-capable" content="yes" />

            <meta property="og:type" content="website" />
            {/* <meta property="og:image" content="/images/og-image.png"/> */}
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:type" content="article" />

            <meta property="og:url" content={canonicalURL} />
            <meta property="og:site_name" content="Arforme" />
          </Head>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </>
      )}
    </Provider>
  )
}

export default appWithTranslation(MyApp)
