import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "CMS";

const initialState = {
  header:       "",
  footer:       "",
  footerSecond: "",
  pages:      null,
};

export const getFooterPages = createAsyncThunk(
  `${name}/footer-pages`,
  async (value) =>
    logger({
      method: "POST",
      url: `${API_URL}/footer-pages`,
    })
);

export const getSecondFooterPages = createAsyncThunk(
  `${name}/footer-pages-second`,
  async (value) =>
    logger({
      method: "POST",
      url: `${API_URL}/footer-pages-second`,
    })
);

export const getHeaderPages = createAsyncThunk(
  `${name}/header-pages`,
  async (value) =>
    logger({
      method: "POST",
      url: `${API_URL}/header-pages`,
    })
);

export const getCMS_Pages = createAsyncThunk(`${name}/pages`, async (value) =>
  logger({
    method: "GET",
    url: `${API_URL}/pages`,
  })
);

const CMS_Slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFooterPages.fulfilled, (state, action) => {
      state.footer = action.payload;
    });
    builder.addCase(getHeaderPages.fulfilled, (state, action) => {
      state.header = action.payload.data;
    });
    builder.addCase(getSecondFooterPages.fulfilled, (state, action) => {
      state.footerSecond = action.payload.data;
    });
    builder.addCase(getCMS_Pages.fulfilled, (state, action) => {
      state.pages = action.payload.data;
    });
  },
});

export const selectFooterPages       = (state) =>      state.CMS.footer;
export const selectHeaderPages       = (state) =>      state.CMS.header;
export const selectSecondFooterPages = (state) =>      state.CMS.footerSecond;
export const selectPage              = (state) =>      state.CMS.pages;

export default CMS_Slice.reducer;
