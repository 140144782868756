import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";


const name = "CONTACT";

const initialState = {
  contatctUsMessage: '',
};

export const contatctUsThunk = createAsyncThunk(
  `${name}/contatctUsThunk`,
  async (values) =>
    logger({
      method: "POST",
      url: `${API_URL}/contact-us`,
      body: {...values},
    })
);

const contatctUsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
    .addCase(contatctUsThunk.fulfilled, (state, action) => {
      state.contatctUsMessage = action.payload;
    });
  },
});

export const contatctUsSelector = (state) => state.contactUs;

export default contatctUsSlice.reducer;