import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { API_URL } from '../api_url'
import logger from '../logger'

const name = 'COSTOMER'

const initialState = {
  customerData: {},
  verificationTypes: [],
  searchedOrders: [],
  ordersInfo: '',
  message: '',
  successMessage: '',
  orderDetails: '',
  productDetails: '',
  trasactionData: {},
}

export const addNewAddress = createAsyncThunk(
  `${name}/createNewAddress`,
  async (params) => {
    try {
      return logger({
        method: 'POST',
        url: `${API_URL}/customer/add-address`,
        body: { ...params },
      })
    } catch (err) {}
  },
)

export const changePassword = createAsyncThunk(
  `${name}/auth/change-password`,
  async (params, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/auth/change-password`,
        body: { ...params },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const changeUserInfo = createAsyncThunk(
  `${name}/customer/info`,
  async (value, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'PUT',
        url: `${API_URL}/customer/info`,
        body: { ...value },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getVerificationTypesThunk = createAsyncThunk(
  `${name}/customer/verification-types`,
  async (value) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/customer/verification-types`,
        body: { ...value },
      })
    } catch (err) {}
  },
)

export const getOrdersInfo = createAsyncThunk(
  `${name}/account/orders-info`,
  async (value) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/account/orders-info`,
        body: { ...value },
      })
    } catch (err) {}
  },
)

export const getOrderDetails = createAsyncThunk(
  `${name}/order-details`,
  async (value) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/account/order-details`,
        body: { ...value },
      })
    } catch (err) {}
  },
)

export const getProductDetails = createAsyncThunk(
  `${name}/get-product`,
  async (value) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/get-product`,
        body: { ...value },
      })
    } catch (err) {}
  },
)

export const deleteCartItem = createAsyncThunk(
  `${name}/account/order-delete`,
  async (value) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/account/order-delete`,
        body: { ...value },
      })
    } catch (err) {}
  },
)

export const getSearchOrders = createAsyncThunk(
  `${name}/account/order-search`,
  async (value) =>
    logger({
      method: 'POST',
      url: `${API_URL}/account/order-search`,
      body: value,
    }),
)

export const getTransactionInfo = createAsyncThunk(
  `${name}/account/transactions`,
  async (value, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/account/transactions`,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getTransactionInfoWithPageThunk = createAsyncThunk(
  `${name}/account/transactions/get=page`,
  async (params, { rejectWithValue }) => {
    try {
      return logger({
        method: 'GET',
        url: `${params}`,
        withoutlocale: true,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

const userSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.fulfilled, (state, action) => {
        state.message = ''
        state.successMessage = action.payload.message
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.message = action.payload.result.message
        state.successMessage = ''
      })
      .addCase(getVerificationTypesThunk.fulfilled, (state, action) => {
        state.verificationTypes = action.payload
      })
      .addCase(getOrdersInfo.fulfilled, (state, action) => {
        state.ordersInfo = action.payload?.[0]
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.orderDetails = action.payload
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.productDetails = action.payload
      })
      .addCase(getSearchOrders.fulfilled, (state, action) => {
        state.searchedOrders = action.payload
      })
      .addCase(getTransactionInfo.fulfilled, (state, action) => {
        state.trasactionData = action.payload
      })
      .addCase(getTransactionInfoWithPageThunk.fulfilled, (state, action) => {
        state.trasactionData = action.payload
      })
      .addMatcher(isAnyOf(addNewAddress.fulfilled), (state, { payload }) => {
        state.customerData = payload?.data
      })
  },
})

export const changePasswordErrmessage = (state) => state.customerData.message
export const changePasswordSuccessMessage = (state) =>
  state.customerData.successMessage
export const selectVerificationTypes = (state) =>
  state.customerData.verificationTypes
export const selectOrdersInfo = (state) => state.customerData.ordersInfo
export const selectOrderDetails = (state) => state.customerData.orderDetails
export const selectProductDetails = (state) => state.customerData.productDetails
export const selectSearchData = (state) => state.customerData.searchedOrders
export const selectTransactionsData = (state) =>
  state.customerData.trasactionData

export default userSlice.reducer
