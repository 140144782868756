import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSecondFooterPages,
  selectSecondFooterPages,
} from "../../redux/slices/CMS_Slice";
import { useTranslation } from "react-i18next";
import Link from "next/link";

export default function Footer_without_logo() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const secondFooterPages = useSelector(selectSecondFooterPages);
  useEffect(() => {
    dispatch(getSecondFooterPages());
  }, []);

  return (
    <div className="footer_without_logo">
      <div className="container">
        <div className="copyright">
          <span>{t("COPYRIGHT_FOOTER_TEXT")}</span>
        </div>
        <div className="items">
          {secondFooterPages &&
            secondFooterPages?.map((item) => {
              if (
                item.slug === "features" ||
                item.slug === "pricing" ||
                item.slug === "partners"
              ) {
                return (
                  <Link href={`/#${item.slug}`} key={item.id}>
                    {item.title}
                  </Link>
                );
              } else {
                return (
                  <Link href={item.slug} key={item.id}>
                    {item.title}
                  </Link>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
