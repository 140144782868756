import Cookies from "js-cookie";

// A mock function to mimic making an async request for data
export default  function fetchCount({ method, url, body, withoutlocale }) {
  const configureBody = {};
  if(!method){
    throw Error
  }else {
    configureBody.method = method
    if (method === "POST" || "PUT"){
      configureBody.body = JSON.stringify(body)
    }
  }

  configureBody.headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": `Bearer ${Cookies.get("authorized")}`,
  }



return new Promise(async (resolve, reject) =>{
  const urlWithLocale = `${url}?locale=${process.browser && localStorage.getItem('i18nextLng') || 'hy'}`
  const response = await fetch(
    url.includes('add-product') ||
    url.includes('delete-cart-item') ||
    url.includes('edit-cart-item-qty') ||
    url.includes('shops/products?page') ||
    url.includes('wishlist?page') ||
    url.includes('notification?page') ||
    url.includes('blog/category/') ||
    url.includes('blogs?page') || 
    url.includes('shops?page') ||
    url.includes('search-product?query') ||
    url.includes('auth/redirect/telcell')
  ? url : urlWithLocale,
    configureBody
    );
    if (response?.ok) {
      try {
        const result = await response.clone().json();
        resolve(result);
      } catch (error) {
        const result = await response.text();
        resolve(result);
      }
    } else {
      const result = await response.json();
      reject({ result, status: response.status });
    }
} )
}

//     return new Promise((resolve, reject) => {
//         if(response.status){
//           resolve(response.json())
//         }else{
//           reject(response.error)
//         }
//     });

//   } catch (err) {
//       throw err
//   }
// }
