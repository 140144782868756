import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../api_url'
import logger from '../logger'
import Cookies from 'js-cookie'
import Router from 'next/router'

const name = 'AUTHENTICATION'

const initialState = {
  loggedIn: !!Cookies.get('authorized'),
  checkEmail: false,
  error: '',
  email: '',
}

export const registerThunk = createAsyncThunk(
  `${name}/registerThunk`,
  async (values, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/auth/register`,
        body: {
          ...values,
        },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const logInThunk = createAsyncThunk(
  `${name}/logInThunk`,
  async (values, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/auth/login`,
        body: {
          ...values,
        },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const logOutThunk = createAsyncThunk(`${name}/logOutThunk`, async () =>
  logger({
    method: 'GET',
    url: `${API_URL}/auth/logout`,
  }),
)

export const emailVerifyThunk = createAsyncThunk(
  `${name}/email/verify`,
  async (value) =>
    logger({
      method: 'GET',
      url: `${API_URL}/email/verify/${value}`,
    }),
)
export const deletedEmailVerifyThunk = createAsyncThunk(
  `${name}/api/send-activation-email?locale=hy`,
  async (value) =>
    logger({
      method: 'POST',
      url: `${API_URL}/send-activation-email?locale=hy`,
      body: {
        ...value,
      },
    }),
)

export const sendForgotPassword = createAsyncThunk(
  `${name}/send-forgot-password-link`,
  async (values, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/send-forgot-password-link`,
        body: {
          ...values,
        },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const loginWithGoogle = createAsyncThunk(
  `${name}/login/google`,
  async (values, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/auth/login/google`,
        body: {
          ...values,
        },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const resetPassword = createAsyncThunk(
  `${name}/reset-password`,
  async (values, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/customer/reset-password`,
        body: {
          ...values,
        },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
export const checkEmailThunk = createAsyncThunk(
  `${name}/check-email`,
  async (values, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/check-email`,
        body: {
          ...values,
        },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const resendEmail = createAsyncThunk(
  `${name}/auth/resend-mail`,
  async (values, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/auth/resend-mail`,
        body: {
          ...values,
        },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const cloaseAccountThunk = createAsyncThunk(
  `${name}/account/delete`,
  async (value) =>
    logger({
      method: 'POST',
      url: `${API_URL}/account/delete`,
      body: value,
    }),
)

const authenticationSlice = createSlice({
  name,
  initialState,
  reducers: {
    setEmail: (state, { payload }) => {
      state.email = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerThunk.fulfilled, (state, action) => {
        state.loggedIn = false
        state.error = ''
        Router.push('/email-verify')
      })
      .addCase(registerThunk.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(logInThunk.fulfilled, (state, action) => {
        Cookies.set('authorized', action.payload.access_token)
        // setExpireTokenToLocal(action.payload.expires_in)
        localStorage.setItem('isLogedIn', String(true))
        state.error = ''
        state.loggedIn = true
        Router.push('/account')
      })
      .addCase(logInThunk.rejected, (state, action) => {
        Cookies.remove('authorized')
        state.error = true
        state.loggedIn = false
      })
      .addCase(logOutThunk.fulfilled, (state, action) => {
        Cookies.remove('authorized')
        state.loggedIn = false
        localStorage.removeItem('isLogedIn')
        Router.push('/')
      })
      .addCase(sendForgotPassword.fulfilled, (state, action) => {
        Cookies.remove('authorized')
        state.loggedIn = false
        localStorage.removeItem('isLogedIn')
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        Cookies.remove('authorized')
        state.loggedIn = false
        localStorage.removeItem('isLogedIn')
      })
      .addCase(checkEmailThunk.fulfilled, (state, action) => {
        !action.payload.inactive_email
          ? (state.checkEmail = true)
          : (state.checkEmail = false)
      })
      .addCase(checkEmailThunk.rejected, (state, action) => {
        state.checkEmail = action
      })
      .addCase(emailVerifyThunk.fulfilled, (state, action) => {
        Router.push('/login')
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        Router.push('/login')
      })
      .addCase(cloaseAccountThunk.fulfilled, (state, action) => {
        Cookies.remove('authorized')
        state.loggedIn = false
        localStorage.removeItem('isLogedIn')
        Router.push('/')
      })
  },
})

export const { setEmail } = authenticationSlice.actions

export const rejectedErrorRegister = (state) => state.authentication.error
export const checkedEmail = (state) => state.authentication.checkEmail
export const verifyMail = (state) => state.authentication.email

export default authenticationSlice.reducer
