import { createSlice } from "@reduxjs/toolkit";


const name = "TRANSLATE";

const initialState = {
    translatedData: false,
}

const translateSlice = createSlice({
    name,
    initialState,
    reducers: {
        setTranslate: (state, { payload }) => {
            state.translatedData = payload;
          },
    },

})

export const isTranslated = (state) =>state.translate.translatedData

export const { setTranslate } = translateSlice.actions;
export default translateSlice.reducer;
