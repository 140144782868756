import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DealsSvg from "../../../public/icons/resources/DealsSvg";
import OnlineShopsSvg from "../../../public/icons/resources/OnlineShopsSvg";
import OrdersSvg from "../../../public/icons/resources/OrdersSvg";
import ReceivedSvg from "../../../public/icons/resources/ReceivedSvg";
import { logOutThunk } from "../../../redux/slices/authenticationSlice";
import { setLoader } from "../../../redux/slices/loaderSlice";
import { getCartThunk } from "../../../redux/slices/cartSlice";
import NotificationsUnred from "../../../public/icons/resources/NotificationsUnred";
import Notifications from "../../../public/icons/resources/Notifications";
import { selectIsNewStatus } from "../../../redux/slices/notificationsSlice";
// import i18next from "i18next";

export default function NotificationPopUp({locale,info, setOpenMenu, changeLanguage}) {
  const [t] = useTranslation();
  const isNewNotifiStatus = useSelector(selectIsNewStatus);
  const router = useRouter();
  const pathname = router.pathname;
  const dispatch = useDispatch()
  function logOut(){
    dispatch(setLoader(true))
    dispatch(logOutThunk()).then(()=>dispatch(getCartThunk)).then(()=>dispatch(setLoader(false)))
  }
// const [locale, setLocale] = useState(router.locale)
//   useEffect(() => {
//     if (router.locale && localStorage.getItem("i18nextLng") !== locale) {
//         i18next.changeLanguage(router.locale)
//         localStorage.setItem("i18nextLng", router.locale)
//     }
//     setLocale(localStorage.getItem('i18nextLng') || "hy")
//     document.getElementsByTagName('html')[0].lang = localStorage.getItem('i18nextLng') || "hy";
// }, [router.locale])

  // function changeLanguage(lng){
  //   router.locale = lng
  //   localStorage.setItem("i18nextLng", lng)
  // }

  function closeMenu(){
    setOpenMenu(false)
  }
  
  return (
    <div className="notificationPopUp">
      <div className="link_with_icons">
        <Link
        onClick={()=>closeMenu()}
          href="/ordered_products"
          className={pathname === "/ordered_products" ? "active" : ''}
        >
          <OrdersSvg /> {t("ORDERS")}{" "}
        </Link>
        <Link onClick={()=>closeMenu()} href="/place_order" className={pathname === "/" ? "active" : ""}>
          <ReceivedSvg />
          Ar for me
        </Link>
        <Link href="/notifications" className="notifications">
          <>
          {isNewNotifiStatus.status === "success" ? (
            <NotificationsUnred />
          ) : (
            <Notifications />
          )}
          {t("NOTIFICATIONS")}
          </>
        </Link>
        <Link onClick={()=>closeMenu()} href="/deals" className={pathname === "/deals" ? "active" : ""}>
          <DealsSvg />
          {t("LEGENDARY_DEALS")}
        </Link>
        <Link onClick={()=>closeMenu()} href="/online-shops" className={pathname === "/online-shops" ? "active" : ""}>
          <OnlineShopsSvg />
          {t("ONLINE_SHOPS")}
        </Link>
      </div>
      <div className="link_with_icons">
        <Link onClick={()=>closeMenu()} href="/account" className={pathname === "/account" ? "active" : ''}>
          {t("ACCOUNT_SETTINGS")}
        </Link>
        <Link onClick={()=>closeMenu()} href="/fill-balance" className={pathname === "/#" ? "active" : ''}>
          {t("BALANCE_FILL")}
        </Link>
        <Link onClick={()=>closeMenu()} href="/transactions" className={pathname === "/#" ? "active" : ''}>
          {t("TRANSACTIONS")}
        </Link>
      </div>
      <div className="link_with_icons">
        <Link href="/" onClick={()=>{logOut(); closeMenu()}}>{t("SIGN_OUT")} {info.name}</Link>
      </div>
      <div className="select_lng">
        <Link href='' onClick={()=>changeLanguage("en")} className={locale === "en" ? "active": ""}>Eng</Link>
        <Link href='' onClick={()=>changeLanguage("hy")} className={locale === "hy" ? "active": ""}>Հայ</Link>
        <Link href='' onClick={()=>changeLanguage("ru")} className={locale === "ru" ? "active": ""}>Рус</Link>
      </div>
    </div>
  );
}
