import React from 'react'

export default function OnlineShopsSvg() {
  return (
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17 21a2 2 0 100-4 2 2 0 000 4zM8 21a2 2 0 100-4 2 2 0 000 4zM3 3h1.192a1 1 0 01.977.79l2.662 12.42a1 1 0 00.977.79H17"
      stroke="#111"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6h13.67a1 1 0 01.962 1.277l-1.731 6a1 1 0 01-.961.723H7.73"
      stroke="#111"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}
