import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";
import Router from "next/router";

const name = "SHOPS";

const initialState = {
    shops: [],
    countries: '',
    products: [],
}

export const getShops = createAsyncThunk(
    `${name}/shops`,
    async (value) =>
        logger({
            method: "POST",
            url: `${API_URL}/shops`,
            body: value
        })
);

export const getShopsCountries = createAsyncThunk(
    `${name}/shops/countries`,
    async (value) =>
        logger({
            method: "POST",
            url: `${API_URL}/shops/countries`,
            body: value
        })
);

export const getCategoriesByCategories = createAsyncThunk(
    `${name}/categories`,
    async (catSlug) =>
        logger({
            method: "GET",
            url: `${API_URL}/shops/category/${catSlug}`,
        })
);

export const getShopsLoadMore = createAsyncThunk(
    `${name}/shops/page`,
    async (params) => {
        try {
            return logger({
                method: 'POST',
                url: `${params.url}`,
                withoutlocale: true,
                body:params.filtersValue,
            })
        } catch (err) {

        }
    }
)

export const getProducts = createAsyncThunk(
    `${name}/shops/products`,
    async (value) =>
        logger({
            method: "POST",
            url: `${API_URL}/shops/products`,
            body: value
        })
);

export const getProductsLoadMore = createAsyncThunk(
    `${name}/shops/products/page`,
    async (params) => {
        try {
            return logger({
                method: 'POST',
                url: `${params.url}`,
                withoutlocale: true,
                body:{status: params.status || ""},
            })
        } catch (err) {

        }
    }
)

export const getSearchProducts = createAsyncThunk(
    `${name}/shops/search-product?query=`,
    async (value) =>
        logger({
            method: "POST",
            url: `${API_URL}/search-product?query=${value}`,
            // body: value
        })
);


const shopsSlice = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: (builder) =>{
        builder
            .addCase(getShops.fulfilled, (state, action) => {
                state.shops = action.payload;
            })
            .addCase(getCategoriesByCategories.fulfilled, (state, action) => {
                state.shops = action.payload;
            })
            .addCase(getShopsCountries.fulfilled, (state, action) => {
                state.countries = action.payload;
            })
            .addCase(getShopsLoadMore.fulfilled, (state, action) => {
                state.shops = action.payload;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.products = action.payload;
            })
            .addCase(getProductsLoadMore.fulfilled, (state, action) => {
                state.products = action.payload;
            })
            .addCase(getSearchProducts.fulfilled, (state, action) => {
                state.products = action.payload;
                Router.push("/deals")
            })
    }
})

export const selectShops = (state) =>state.shops.shops;
export const selectCountries = (state) =>state.shops.countries;
export const productSelector = (state) => state.shops.products;

export default shopsSlice.reducer;
