import { createSlice } from "@reduxjs/toolkit";

const name = "LOADER";

const initialState = {
  loader: false,
}

const loaderSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLoader: (state, { payload }) => {
      state.loader = payload;
    },
  },
});

export const isLoaded = (state) => state.loader.loader;

export const { setLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
