import i18next from 'i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded } from '../redux/slices/loaderSlice'
import { isTranslated } from '../redux/slices/translitedSlice'
import { selectUserInfoErr } from '../redux/slices/userSlice'
import Footer from './common/Footer'
import Footer_without_logo from './common/Footer_without_logo'
import Header from './common/Header'
import Header_login_reg from './common/Header_login_reg'
import Loader from './Loader'
import { getCartThunk, selectCartData } from '../redux/slices/cartSlice'
import MultipleProductBar from './MultipleProductBar'
import { newNotificationsThunk } from '../redux/slices/notificationsSlice'
import Head from 'next/head'

export default function Layout({ children }) {
  const translated = useSelector(isTranslated)
  const userInfoErr = useSelector(selectUserInfoErr)
  const cartData = useSelector(selectCartData)
  const router = useRouter()
  const dispatch = useDispatch()
  const pathname = router.pathname
  const footer2 = document.querySelector('.footer_without_logo')
  const footer = document.querySelector('.footer')
  const body = document.body
  const [isLogedIn, setIsLogedIn] = useState(localStorage.getItem('isLogedIn'))
  const [scrollY, setScrollY] = useState(0)
  const [activeMultipleCart, setActiveMultipleCart] = useState(false)

  function logit() {
    let bodyHeight = Math.max(body.scrollHeight)
    if (window.pageYOffset > scrollY) {
      setActiveMultipleCart(true)
    } else {
      setActiveMultipleCart(false)
    }
    if (-bodyHeight + window.innerHeight + scrollY > -300) {
      setActiveMultipleCart(false)
    }
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    setActiveMultipleCart(true)
  }, [cartData])

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit)
    }
    watchScroll()
    return () => {
      window.removeEventListener('scroll', logit)
    }
  })

  let resizeObserver = new ResizeObserver(() => {
    var bodyHeight = Math.max(body.scrollHeight)
    if (bodyHeight + 200 < window.innerHeight && bodyHeight !== 0) {
      footer2?.classList.add('absoluteFooter2')
      footer?.classList.add('absoluteFooter2')
    } else {
      footer2?.classList.remove('absoluteFooter2')
      footer?.classList.remove('absoluteFooter2')
    }
  })
  resizeObserver.observe(body)

  useEffect(() => {
    setIsLogedIn(localStorage.getItem('isLogedIn'))
    isLogedIn && dispatch(newNotificationsThunk())
  }, [localStorage.getItem('isLogedIn')])

  useEffect(() => {
    if (router.locale && localStorage.getItem('i18nextLng') !== router.locale) {
      i18next.changeLanguage(router.locale)
      localStorage.setItem('i18nextLng', router.locale)
    }
    document.getElementsByTagName('html')[0].lang =
      localStorage.getItem('i18nextLng') || 'hy'
  }, [router.locale])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng') || 'hy')
  }, [translated, router.locale])

  useEffect(() => {
    if (userInfoErr.status === 401 && pathname !== '/auth/sign-in') {
      localStorage.removeItem('isLogedIn')
      localStorage.removeItem('token')
      dispatch(getCartThunk)
    }
    // else {
    //  localStorage.setItem("isLogedIn", String(true));
    // }
  }, [userInfoErr])

  useEffect(() => {
    dispatch(getCartThunk())
  }, [])

  function chooseHeader() {
    switch (pathname) {
      case '/registration':
      case '/forgot_password':
      case '/login':
      case '/create_new_password':
      case '/email-verify':
        return <Header_login_reg />
        break

      default:
        return <Header />
    }
  }

  function chooseFooter() {
    switch (pathname) {
      case '/registration':
      case '/forgot_password':
      case '/login':
      case '/create_new_password':
      case '/place_order':
      case '/cart':
      case '/account':
      case '/ordered_products':
      case '/transactions':
      case '/email-verify':
      case '/fill-balance':
        return <Footer_without_logo />
        break

      default:
        return <Footer />
    }
  }

  function unsetMultipleCartSection() {
    switch (pathname) {
      case '/registration':
      case '/forgot_password':
      case '/login':
      case '/create_new_password':
      case '/place_order':
      case '/cart':
      case '/transactions':
      case '/email-verify':
      case '/auth/redirect/telcell':
      case '/checkout':
        return false
        break

      default:
        return <MultipleProductBar active={activeMultipleCart} />
    }
  }

  return (
    <>
      <Loader />
      {chooseHeader()}
      {children}
      {chooseFooter()}
      {isLogedIn &&
        cartData?.cart?.cartinfo?.items?.length &&
        unsetMultipleCartSection()}
    </>
  )
}
