import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "WISHLIST";

const initialState = {
    savedProducts: [],
}

export const getSavedProducts = createAsyncThunk(
    `${name}/wishlist`,
    async () =>
        logger({
            method: "GET",
            url: `${API_URL}/wishlist`,
        })
);

export const getSavedProductsWithPageThunk = createAsyncThunk(
    `${name}/wishlist/get=page`,
    async (params) => {
        try {
            return logger({
                method: 'GET',
                url: `${params}`,
                withoutlocale: true,
                // body: {...params},
            })
        } catch (err) {

        }
    }
)


export const addToWishlist = createAsyncThunk(
    `${name}/wishlist/product`,
    async (value) =>
        logger({
            method: "POST",
            url: `${API_URL}/wishlist/product`,
            body: value
        })
);

const savedProducts = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: (builder) =>{
        builder
            .addCase(getSavedProducts.fulfilled, (state, action) => {
                state.savedProducts = action.payload;
            })
            .addCase(getSavedProductsWithPageThunk.fulfilled, (state, action) => {
                state.savedProducts = action.payload;
            })
    }
})

export const selectSavedProducts = (state) =>state.savedProducts.savedProducts

export default savedProducts.reducer;
