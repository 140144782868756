import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "BLOG";

const initialState = {
  blogs: undefined,
  blogDetails: undefined,
};

export const getBlogs = createAsyncThunk(`${name}/blogs`, async (value) =>
  logger({
    method: "POST",
    url: `${API_URL}/blogs`,
    body: value,
  })
);

export const getBlogDetails = createAsyncThunk(`${name}/blog`, async (blogId) =>
  logger({
    method: "GET",
    url: `${API_URL}/blog/${blogId}`,
  })
);

export const getBlogsWithCategory = createAsyncThunk(
  `${name}/blogsCategory`,
  async (value) =>
    logger({
      method: "POST",
      url: `${API_URL}/blog/category/${value}`,
      // body: value,
    })
);

export const getBlogsWithCategoryPage = createAsyncThunk(
  `${name}/blogsCategory`,
  async (value) =>
    logger({
      method: "POST",
      url: `${value}`,
      // body: value,
    })
);

const blogsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.blogs = action.payload;
      })
      .addCase(getBlogDetails.fulfilled, (state, action) => {
        state.blogDetails = action.payload;
      })
      .addCase(getBlogsWithCategory.fulfilled, (state, action) => {
        state.blogs = action.payload;
      })
  },
});

export const selectBlogs = (state) => state.blogs.blogs;
export const selectBlogDetails = (state) => state.blogs.blogDetails;

export default blogsSlice.reducer;
