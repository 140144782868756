import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "PRICING";

const initialState = {
    pricing: {},
};

export const pricingThunk = createAsyncThunk(`${name}/pricing`, async () =>
  logger({
    method: "POST",
    url: `${API_URL}/pricing`,
  })
);

const pricingSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(pricingThunk.fulfilled, (state, action) => {
      state.pricing = action.payload;
    });
  },
});

export const selectPricingData = (state) => state.pricing.pricing;

export default pricingSlice.reducer;
