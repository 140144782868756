import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";


const name = "TOP_SLIDER";

const initialState = {
    topSlider: {},
}

export const topSliderThunk = createAsyncThunk(
    `${name}/top-slider`,
    async (values) =>
      logger({
        method: "POST",
        url: `${API_URL}/top-slider`,
        body: {...values},
      })
  );


const topSlider = createSlice({
    name,
    initialState,
    extraReducers: (builder) =>{
        builder
        .addCase(topSliderThunk.fulfilled, (state, action) => {
            state.topSlider = action.payload;
    })

}})

export const selectTopSlider = (state) =>state.topSlider.topSlider

export default topSlider.reducer;

