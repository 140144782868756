import React from 'react'

export default function DealsSvg() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.034 12.52l7.447 7.446a1 1 0 001.414 0l7.071-7.07a1 1 0 000-1.415L12.52 4.034a1 1 0 00-.797-.289l-6.482.59a1 1 0 00-.905.905l-.59 6.482a1 1 0 00.29.797z"
        stroke="#111"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.299 8.299a1 1 0 111.414 1.414 1 1 0 01-1.414-1.414z"
        stroke="#111"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
