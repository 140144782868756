import { Router } from "next/router";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { isLoaded, setLoader } from "../redux/slices/loaderSlice";

export default function Loader() {
  const isLoadedState = useSelector(isLoaded);
  const dispatch = useDispatch()

  Router.events.on("routeChangeStart", () => {
    dispatch(setLoader(true))
  });
  Router.events.on("routeChangeComplete", () => {
    dispatch(setLoader(false))
  });
  useEffect(() => { 
    if (isLoadedState === true) {
      document.body.classList.add("loaderOn");
    } else {
      document.body.classList.remove("loaderOn");
    }
  }, [isLoadedState]);

  return (
    <>
      {isLoadedState && (
        <div className="loader_block">
          <FadeLoader
            color={"#6033df"}
            loading={isLoadedState}
            // cssOverride={override}
            size={250}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
