import Link from "next/link";
import React, { useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import {
  getFooterPages,
  selectFooterPages,
} from "../../redux/slices/CMS_Slice";
import { useTranslation } from "react-i18next";
import LogoPurple from "../../public/icons/resources/LogoPurple";

export default function Footer() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const footerPages = useSelector(selectFooterPages);

  useEffect(() => {
    dispatch(getFooterPages());
  }, []);

  return (
    <div className="footer">
      <div className="footer__container container">
        <div className="footer_main">
          <div>
            <LogoPurple width={191} />
            <p className="footer_text">{t("FOOTER_TEXT")}</p>
            {!isMobile && (
              <div className="footer_icons">
                <Link
                  aria-label="facebook"
                  href="https://www.facebook.com/arforme.am"
                  target="_blank"
                  className="icon-facebook_content"
                ></Link>
                <Link
                  aria-label="instagram"
                  href="https://www.instagram.com/arforme.am/"
                  target="_blank"
                  className="icon-instagram_content"
                ></Link>
                <Link
                  aria-label="twitter"
                  href="/#"
                  target="_blank"
                  className="icon-twitter_content"
                ></Link>
                <Link
                  aria-label="telegram"
                  href="/#"
                  target="_blank"
                  className="icon-telegram_content"
                ></Link>
              </div>
            )}
          </div>
          <div className="footer_menus">
            <div className="footer_menu">
              <span className="footer_menu_title">{t("COMPANY")}</span>
              <ul>
                {footerPages?.company?.map((item, index) => {
                  return (
                    <li className="menu_item" key={index}>
                      <Link href={item.slug}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer_menu">
              <span className="footer_menu_title">{t("PRODUCT")}</span>
              <ul>
                {footerPages?.product?.map((item, index) => {
                  if(item.slug === "features" || item.slug === "pricing" || item.slug === "partners"){
                    return (
                      <li className="menu_item" key={index}>
                          <Link href={`/#${item.slug}`}>{item.title}</Link>
                      </li>
                    );
                  } else{
                    return (
                      <li className="menu_item" key={index}>
                          <Link href={item.slug}>{item.title}</Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div className="footer_menu">
              <span className="footer_menu_title">{t("HELP_AND_CONTACT")}</span>
              <ul>
                {footerPages?.help_and_contact?.map((item, index) => {
                  return (
                    <li className="menu_item" key={index}>
                      <Link href={item.slug}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {isMobile && (
          <div className="footer_icons footer_icons_mobile">
            <Link
              href="https://www.facebook.com/arforme.am"
              target="_blank"
              className="icon-facebook_content"
            ></Link>
            <Link
              href="https://www.instagram.com/arforme.am/"
              target="_blank"
              className="icon-instagram_content"
            ></Link>
            <Link
              href="/#"
              target="_blank"
              className="icon-twitter_content"
            ></Link>
            <Link
              href="/#"
              target="_blank"
              className="icon-telegram_content"
            ></Link>
          </div>
        )}

        <p className="copyright_text">{t("COPYRIGHT_FOOTER_TEXT")}</p>
      </div>
    </div>
  );
}
