import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import MobileMenu from './details/MobileMenu'
import RegistredHeaderDetails from './details/RegistredHeaderDetails'
import { useDispatch, useSelector } from 'react-redux'
import { selectCartData } from '../../redux/slices/cartSlice'
import { selectUserInfo } from '../../redux/slices/userSlice'
import { useTranslation } from 'react-i18next'
import UserAccountSvg from '../../public/icons/resources/UserAccountSvg'
import { getSearchOrders } from '../../redux/slices/customerSlice'
import LogoGreen from '../../public/icons/resources/LogoGreen'
import LogoPurple from '../../public/icons/resources/LogoPurple'
import ArForMeLogo from '../../public/icons/resources/ArForMeLogo'

export default function Header() {
  const [t] = useTranslation()
  const size = useWindowSize()
  const dispatch = useDispatch()
  const isMobile = size.width <= 768
  const isTablet = size.width <= 1200 && size.width > 768
  const cartData = useSelector(selectCartData)
  const menuItems = [
    { title: t('DEALS'), slug: '/deals' },
    { title: t('FEATURES'), slug: '/#features' },
    { title: t('PRICING'), slug: '/#pricing' },
    { title: t('SHOPS'), slug: '/online-shops' },
    { title: t('FAQs'), slug: '/faqs' },
    { title: t('CONTACT_US'), slug: '/contact-us' },
  ]
  const [openMenu, setOpenMenu] = useState(false)
  const [isLogedIn, setIsLogedIn] = useState(localStorage.getItem('isLogedIn'))
  const [activeSearch, setActiveSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const routher = useRouter()
  const path = routher.pathname

  const openMobileMenu = function () {
    setOpenMenu(!openMenu)
    document.querySelector('body').classList.toggle('active_obile_menu')
    document.querySelector('.header').classList.remove('active_not_menu')
  }

  useEffect(() => {
    setIsLogedIn(localStorage.getItem('isLogedIn'))
  }, [localStorage.getItem('isLogedIn')])

  function seachrAbsolute() {
    // (size.width < 1200 && size.width > 992)
    // ||
    size.width < 460 && setActiveSearch(!activeSearch)
  }

  function unActiveSearch(e) {
    setActiveSearch(false)
    setSearchValue('')
  }

  function searchFunc(e) {
    e.preventDefault()
    searchValue && dispatch(getSearchOrders({ query: searchValue }))
    // setActiveSearch(false)
    // setSearchValue("")
  }

  function displayingSearch() {
    if (
      path.includes('deals') ||
      path.includes('online-shops') ||
      path.includes('faqs') ||
      path.includes('contact-us') ||
      path.includes('blogs') ||
      path.includes('terms-and-conditions') ||
      path.includes('404 ') ||
      path === '/'
    ) {
      return false
    }
    return true
  }

  //  const isLogedIn = localStorage.getItem("isLogedIn")

  return (
    <>
      <div
        className={`${
          path === '/' || path === '/contact-us' || openMenu
            ? 'header'
            : 'header header_black_color'
        } `}
      >
        <div
          className={
            isLogedIn ? 'header__main loged_in_header_main' : 'header__main'
          }
        >
          <div className="header__logo_menu">
            <div
              role="button"
              onClick={() => {
                if (openMenu) openMobileMenu()
              }}
            >
              <Link aria-label="home" href="/">
                {path === '/' || path === '/contact-us' ? (
                  <LogoGreen />
                ) : (
                  <LogoPurple />
                )}
              </Link>
            </div>
            {!isMobile && !isTablet && (
              <div className="header__menu">
                {menuItems?.map((item, index) => {
                  {
                    return (
                      <Link href={item.slug} key={index}>
                        <span> {item.title} </span>
                      </Link>
                    )
                  }
                })}
              </div>
            )}
          </div>
          <div className="header__top_bar_wrapper">
            {!openMenu && displayingSearch() && (
              <form
                onSubmit={(e) => {
                  searchFunc(e)
                }}
                onClick={seachrAbsolute}
                className={activeSearch ? 'active_search search' : 'search'}
              >
                <input
                  onClick={(e) => activeSearch && e.stopPropagation()}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={t('SEARCH')}
                  type="text"
                />
                {activeSearch && (
                  <span
                    onClick={(e) => unActiveSearch(e)}
                    class="close icon-close_content"
                  ></span>
                )}
                <span
                  onClick={(e) => {
                    activeSearch && e.stopPropagation()
                    searchFunc(e)
                  }}
                  className="icon-search_content"
                ></span>
              </form>
            )}
            {!isMobile &&
              !isTablet &&
              (isLogedIn ? (
                <RegistredHeaderDetails setOpenMenu={setOpenMenu} />
              ) : (
                <div className="header__top_bar">
                  <Link href="/login">
                    <div className="header__top_bar__sign_in">
                      <span>{t('SIGN_IN')}</span>
                    </div>
                  </Link>
                  <Link href="/registration">
                    <div className="header__top_bar__get_started">
                      <span>{t('GET_STARTED')}</span>
                    </div>
                  </Link>
                  {isLogedIn && (
                    <Link href="/cart" className="cart">
                      <span className="icon-bag_content"></span>
                      <div className="cart_count">
                        {cartData?.cart?.cartinfo?.items?.length || 0}
                      </div>
                    </Link>
                  )}
                </div>
              ))}
          </div>
          {/* For Mobile */}
          {(isMobile || isTablet) && (
            <div className="incons_wrapper">
              {isLogedIn && (
                <Link
                  href="/account"
                  onClick={() => {
                    openMenu && openMobileMenu()
                  }}
                >
                  <UserAccountSvg />
                </Link>
              )}
              <span
                className={
                  !openMenu
                    ? 'icon-menu_mobile_content header__mobile_menu_content'
                    : 'icon-close_content header__mobile_menu_content'
                }
                onClick={openMobileMenu}
              ></span>
            </div>
          )}
        </div>
      </div>
      {openMenu && (
        <MobileMenu
          isLogedIn={isLogedIn}
          menuItems={menuItems}
          openMobileMenu={openMobileMenu}
          setOpenMenu={setOpenMenu}
        />
      )}
    </>
  )
}
