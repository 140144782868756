import React from 'react'

export default function ReceivedSvg() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.152 8H5.847a1 1 0 00-.987.836l-1.666 10A1 1 0 004.18 20h15.639a1 1 0 00.986-1.164l-1.666-10A1 1 0 0018.152 8zM8 8a4 4 0 118 0"
        stroke="#111"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
