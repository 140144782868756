import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "NOTIFICATIONS";

const initialState = {
  notificationsSettings: [],
  notifications:[],
  isNew:"",
};

export const getNotificationsSettingsThunk = createAsyncThunk(
  `${name}/notificaton`,
  async () =>
    logger({
      method: "GET",
      url: `${API_URL}/notification`,
    })
);

export const updateNotifications = createAsyncThunk(
    `${name}/notification/update`,
    async (params) => {
        try {
            return logger({
                method: 'POST',
                url: `${API_URL}/notification/update`,
                body: {...params},
            })
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const getNotificationsThunk = createAsyncThunk(
    `${name}/notification/get`,
    async (params,{rejectWithValue}) => {
        try {
            return logger({
                method: 'POST',
                url: `${API_URL}/notification/get`,
                body: {...params},
            })
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const getNotificationsWithPageThunk = createAsyncThunk(
    `${name}/notification/get=page`,
    async (params) => {
        try {
            return logger({
                method: 'POST',
                url: `${params}`,
                withoutlocale: true,
                // body: {...params},
            })
        } catch (err) {

        }
    }
)

export const readNotificationsThunk = createAsyncThunk(
    `${name}/notification/read`,
    async (params) => {
        try {
            return logger({
                method: 'POST',
                url: `${API_URL}/notification/read`,
                body: {...params},
            })
        } catch (err) {

        }
    }
)

export const bulkSelectNotificationsThunk = createAsyncThunk(
    `${name}/notification/bulk-select`,
    async (params) => {
        try {
            return logger({
                method: 'POST',
                url: `${API_URL}/notification/bulk-select`,
                body: {...params},
            })
        } catch (err) {

        }
    }
)

export const newNotificationsThunk = createAsyncThunk(
    `${name}/notification/new`,
    async (params) => {
        try {
            return logger({
                method: 'POST',
                url: `${API_URL}/notification/new`,
                body: {...params},
            })
        } catch (err) {

        }
    }
)

const notificationsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getNotificationsSettingsThunk.fulfilled, (state, action) => {
          state.notificationsSettings = action.payload;
        })
        .addCase(getNotificationsThunk.fulfilled, (state, action) => {
            state.notifications = action.payload;
          })
          .addCase(getNotificationsWithPageThunk.fulfilled, (state, action) => {
            state.notifications = action.payload;
          })
          .addCase(newNotificationsThunk.fulfilled, (state, action) => {
            state.isNew = action.payload;
          })
        // .addCase(updateNotifications.fulfilled, (state, action) => {
        //     state.notifications = action.payload;
        // })
  },
});

export const selectNotificationsSettings = (state) => state.notifications.notificationsSettings;
export const selectNotifications = (state) => state?.notifications?.notifications;
export const selectIsNewStatus = (state) => state?.notifications?.isNew;


export default notificationsSlice.reducer;
