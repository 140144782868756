import { combineReducers, configureStore } from "@reduxjs/toolkit";
import shopsReducer from './slices/getShopsSlice'
import blogsReducer from './slices/blogsSlice'
import partnersReducer from './slices/partnerSlice'
import contactUsReducer from './slices/contactUsSlice'
import authenticationReducer from './slices/authenticationSlice'
import categoriesReducer from './slices/categoriesSlice'
import customerReducer from './slices/customerSlice'
import translateReducer from './slices/translitedSlice'
import userReducer from './slices/userSlice'
import topSliderReducer from './slices/topSliderSlice'
import FAQsReducer from './slices/FAQsSlice'
import pricingReducer from './slices/pricingSlice'
import whyChooseUsReducer from './slices/why_choose_us'
import savedProductsReducer from './slices/savedProductsSlice'
import loaderReducer from './slices/loaderSlice'
import termsReducer from './slices/TermsSlice'
import notificationsReducer from "./slices/notificationsSlice"
import placeOrderReducer from "./slices/placeOrderSlice"
import cartReducer from "./slices/cartSlice"
import CMS_Reducer from "./slices/CMS_Slice"
import CheckoutReducer from "./slices/checkoutSlice"


const combinedReducers = combineReducers({
    shops:shopsReducer,
    blogs: blogsReducer,
    partners: partnersReducer,
    contactUs: contactUsReducer,
    authentication: authenticationReducer,
    categories: categoriesReducer,
    customerData: customerReducer,
    translate: translateReducer,
    user: userReducer,
    topSlider: topSliderReducer,
    FAQs: FAQsReducer,
    pricing: pricingReducer,
    whyChooseUs: whyChooseUsReducer,
    savedProducts: savedProductsReducer,
    loader: loaderReducer,
    terms: termsReducer,
    notifications: notificationsReducer,
    placeOrder: placeOrderReducer,
    cart: cartReducer,
    CMS: CMS_Reducer,
    checkout:CheckoutReducer,
});

export default configureStore({
  reducer: combinedReducers,
});
