import React from 'react'

export default function Notifications() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 20H4zM20 8l-7.475 4.675c-.083.05-.171.087-.263.112a.981.981 0 01-.262.038.981.981 0 01-.262-.038 1.026 1.026 0 01-.263-.112L4 8v10h16V8zm-8 3l8-5H4l8 5zM4 8.25V6.775 6.8v-.013V8.25z"
          fill="#2b2b2b"
        />
      </g>
    </svg>
  )
}
