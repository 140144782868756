import React from 'react'

export default function ArForMeLogo({color}) {
  return (
    <svg
      className={color}
      width= "32px"
      height="32px"
      viewBox="0 0 1000.000000 1000.000000"
    >
      <path
        d="M3750 7163c-350-36-660-135-947-304-89-52-282-190-329-233-155-145-267-267-344-374-85-118-210-332-194-332 4 0 0-8-8-17-23-26-102-232-132-343-191-707-9-1466 486-2017 283-315 672-549 1083-652 200-50 244-54 626-58l367-5 33 30 34 30v874l-31 27-31 28-294 6c-312 6-374 14-524 63-95 32-238 101-317 155-78 53-224 194-279 268-94 130-163 276-201 428-32 128-32 388 0 518 53 214 130 360 278 531 29 34 51 64 49 68-3 3-2 4 1 1 4-2 35 19 70 48 128 107 287 187 464 234 89 24 115 26 396 32 299 6 301 6 337 31 22 14 48 46 62 74l25 50v377c0 221-4 388-10 403-5 14-24 35-42 46-31 19-49 20-313 18-154 0-296-3-315-5zM5675 7154c-45-23-66-45-86-89-17-36-19-72-19-395 0-407 2-415 82-467l43-28 275-6c291-6 344-13 498-65 273-91 515-289 656-534 262-458 185-1019-193-1399-130-132-265-217-443-279-155-55-225-64-543-69-163-2-302-9-317-15-56-21-58-37-58-490 0-442-1-436 50-475 24-20 642-17 779 2 475 69 890 273 1220 598 350 345 558 750 637 1242 25 151 25 466 0 624-63 410-239 789-507 1091-382 431-873 689-1437 755-181 21-596 21-637-1z"
        transform="matrix(.1 0 0 -.1 0 1000)"
      />
      <path
        d="M3884 5475l-25-25 3-461c3-443 4-461 22-475 17-12 183-14 1097-14h1078l15 22c14 20 16 82 16 480v457l-22 21c-22 20-29 20-1090 20H3909l-25-25z"
        transform="matrix(.1 0 0 -.1 0 1000)"
      />
    </svg>
  )
}
