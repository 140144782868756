import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../api_url'
import logger from '../logger'

const name = 'CHECKOUT'

const initialState = {
  message: '',
}

export const checkoutThunk = createAsyncThunk(
  `${name}/checkout`,
  async (values) =>
    logger({
      method: 'POST',
      url: `${API_URL}/checkout`,
      body: { ...values },
    }),
)

export const requestTelcellThunk = createAsyncThunk(
  `${name}/telcell`,
  async (values) =>
    logger({
      method: 'POST',
      url: `${values}`,
      // body: {...values},
    }),
)

export const fillBalanceThunk = createAsyncThunk(
  `${name}/account/fill-balance`,
  async (params, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'POST',
        url: `${API_URL}/account/fill-balance`,
        body: { ...params },
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const sendNumberThunk = createAsyncThunk(
  `${name}/auth/redirect/telcell`,
  async (params, { rejectWithValue }) => {
    try {
      return await logger({
        method: 'GET',
        url: `${API_URL}/auth/redirect/telcell?order=${params}`,
        // body: {...params}
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

const checkoutSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(checkoutThunk.fulfilled, (state, action) => {
        state.message = action.payload
      })
      .addCase(sendNumberThunk.fulfilled, (state, action) => {
        state.status = action.payload
      })
  },
})

export const contatctUsSelector = (state) => state.checkout

export default checkoutSlice.reducer
