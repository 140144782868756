import { API_URL } from "../redux/api_url";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import i18next from "i18next";
import store from "../redux/store";
import { setTranslate } from "../redux/slices/translitedSlice";

const lng = (process.browser && localStorage.getItem("i18nextLng")) || "hy";

export const loadResources = async () => {
  let langData = { en: {}, hy: {}, ru: {} };
  const { dispatch } = store;

  const res = await axios(`${API_URL}/get-translation-list`, {
    method: "POST",
  }).then((res) => res.data);

  const objKeys = res.data.map((item) => {
    return Object.keys(item);
  });

  const objData = res.data.reduce((acc, el, i) => {
    acc[objKeys[i]] = el[objKeys[i]];
    return acc;
  }, {});

  const keys = Object.keys(objData);

  keys.forEach((element) => {
    langData.en[element] = objData[element].en;
    langData.hy[element] = objData[element].hy;
    langData.ru[element] = objData[element].ru;
  });
  
  i18n.addResourceBundle("hy", "translation", langData.hy);
  i18n.addResourceBundle("en", "translation", langData.en);
  i18n.addResourceBundle("ru", "translation", langData.ru);

  dispatch(setTranslate(true));

  return objData;
};

export default i18next.use(initReactI18next).init({
  resources: {},
  lng: lng,
  react: { wait: true, useSuspense: true },
});
loadResources();
