import React from 'react'

export default function OrdersSvg() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20 8H4v11a1 1 0 001 1h14a1 1 0 001-1V8zM12 11v6"
        stroke="#111"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15l-3 2-3-2M17.134 4H6.866a1 1 0 00-.868.504L4 8h16l-1.998-3.496A1 1 0 0017.134 4z"
        stroke="#111"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
