import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "TERMS";

const initialState = {
  TermsData: {},
};

export const TermsThunk = createAsyncThunk(
  `${name}/terms-of-service`,
  async (values) =>
    logger({
      method: "POST",
      url: `${API_URL}/terms-of-service`,
      body: { ...values },
    })
);

const TermsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(TermsThunk.fulfilled, (state, action) => {
      state.TermsData = action.payload.data[0];
    });
  },
});

export const selectTermsData = (state) => state.terms.TermsData;

export default TermsSlice.reducer;
