import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "CATEGORIES";

const initialState = {
    categories: [],
    blogCategories: [],
}

export const getCategories = createAsyncThunk(
    `${name}/categories`,
    async () =>
        logger({
            method: "GET",
            url: `${API_URL}/categories`,
        })
);

export const getBlogCategories = createAsyncThunk(
    `${name}/BlogGcategories`,
    async () =>
        logger({
            method: "POST",
            url: `${API_URL}/blog-categories`,
        })
);


const categoriesSlice = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: (builder) =>{
        builder
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
            })
            .addCase(getBlogCategories.fulfilled, (state, action) => {
                state.blogCategories = action.payload;
            })

    }
})

export const categoriesSelector = (state) =>state.categories.categories.data
export const blogCategoriesSelector = (state) =>state.categories.blogCategories


export default categoriesSlice.reducer;
