import Link from 'next/link'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import useWindowSize from '../../../hooks/useWindowSize'
import Notifications from '../../../public/icons/resources/Notifications'
import NotificationsUnred from '../../../public/icons/resources/NotificationsUnred'
import { selectIsNewStatus } from '../../../redux/slices/notificationsSlice'
import { selectUserInfo } from '../../../redux/slices/userSlice'
import NotificationPopUp from '../../account/details/NotificationPopUp'
import { selectCartData } from '../../../redux/slices/cartSlice'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import i18next from 'i18next'
import ArForMeLogo from '../../../public/icons/resources/ArForMeLogo'

export default function RegistredHeaderDetails({ setOpenMenu }) {
  const [t] = useTranslation()
  const userInfo = useSelector(selectUserInfo)
  const isNewNotifiStatus = useSelector(selectIsNewStatus)
  const [activePopUp, setActivePopup] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const cartData = useSelector(selectCartData)
  const { name, surname } = userInfo
  const size = useWindowSize()
  const isTablet = size.width <= 992 && size.width > 768
  const isMobile = size.width <= 768
  const isDesktop = size.width > 992
  const router = useRouter()
  const pathname = router.pathname
  const [locale, setLocale] = useState(router.locale)

  function changeLanguage(lng) {
    router.locale = lng
    localStorage.setItem('i18nextLng', lng)
    setLocale(localStorage.getItem('i18nextLng') || 'hy')
  }

  useEffect(() => {
    // if (router.locale && localStorage.getItem("i18nextLng") !== locale) {
    i18next.changeLanguage(router.locale)
    // localStorage.setItem("i18nextLng", router.locale)
    // }
    setLocale(localStorage.getItem('i18nextLng') || 'hy')
    document.getElementsByTagName('html')[0].lang =
      localStorage.getItem('i18nextLng') || 'hy'
  }, [router.locale])

  useEffect(() => {
    if (activePopUp) {
      document.querySelector('.header').classList.add('active_not_menu')
    } else {
      document.querySelector('.header').classList.remove('active_not_menu')
    }
  }, [activePopUp])

  return (
    <div className="RegistredHeaderDetailsStyle">
      {(!isTablet || isMobile || isDesktop) && (
        <div className="balance">
          <span>{t('BALANCE')}: </span>
          <span>
            {' '}
            {userInfo?.account_current_balance?.toLocaleString()} (AMD)
          </span>
        </div>
      )}
      {!isMobile && (
        <div
          role="button"
          className="logo_wrapper"
          onClick={() => {
            router.push('/place_order')
            setOpenMenu(false)
          }}
        >
          <ArForMeLogo
            color={
              pathname === '/' || pathname === '/contact-us'
                ? 'green'
                : 'purple'
            }
          />
        </div>
      )}
      {/* {!isMobile && (
        <Link href="/notifications" className="notifications">
          {isNewNotifiStatus.status === "success" ? (
            <NotificationsUnred />
          ) : (
            <Notifications />
          )}
        </Link>
      )} */}
      <Link onClick={() => setOpenMenu(false)} href="/cart" className="cart">
        <span className="icon-bag_content"></span>
        <div className="cart_count">
          {cartData?.cart?.cartinfo?.items?.length || 0}
        </div>
      </Link>
      <div className="userName" onClick={() => setActivePopup(!activePopUp)}>
        <p>
          {name} {surname}
        </p>
        {/* POPUP */}
        {activePopUp && (
          <NotificationPopUp
            locale={locale}
            changeLanguage={changeLanguage}
            info={userInfo}
            setOpenMenu={setOpenMenu}
          />
        )}
      </div>
    </div>
  )
}
