import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";


const name = "PARTNERS";

const initialState = {
    partners: [],
}

export const getPartners = createAsyncThunk(
    `${name}/partners`,
    async () =>
        logger({
            method: "GET",
            url: `${API_URL}/partners`,
        })
);

const partnersSlice = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: (builder) =>{
        builder.addCase(getPartners.fulfilled, (state, action) => {
            state.partners = action.payload;
        })
    }
})

export const selectPartners = (state) =>state.partners.partners


export default partnersSlice.reducer