import React from "react";
import { useSelector } from "react-redux";
import { selectCartData } from "../redux/slices/cartSlice";
import { useTranslation } from "react-i18next";
import BtnArrowAnimation from "../public/icons/resources/btnArrowAnimation";
import Image from "next/legacy/image";
import Link from "next/link";
import { useEffect } from "react";
import { useState } from "react";
import useWindowSize from "../hooks/useWindowSize";

export default function MultipleProductBar({ active }) {
  const [t] = useTranslation();
  const data = useSelector(selectCartData);
  const [mapData, setMapData] = useState([]);
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  useEffect(() => {
    if (data?.cart?.cartinfo?.items?.length <= 5) {
      setMapData(data?.cart?.cartinfo?.items.slice(0, 5));
    }
    if (data?.cart?.cartinfo?.items?.length > 5) {
      setMapData(data?.cart?.cartinfo?.items.slice(0, 4));
    }
  }, [data]);
  
  return (
    <div
      className={
        active
          ? "active_multipleProductBar multipleProductBar"
          : " multipleProductBar"
      }
    >
      <div className="container">
        {!isMobile && (
          <>
            <div className="main_block">
              <div className="items_wrapper">
                {mapData.map((item) => {
                  return (
                    <div key={item.id} className="item">
                      <div className="img_block">
                        <Image
                          className="image"
                          height={1}
                          width={1}
                          layout="responsive"
                          alt={item?.name ||"products"}
                          objectFit="cover"
                          src={item.images?.[0]}
                        />
                      </div>
                      {data?.cart?.cartinfo?.items?.length === 1 && (
                        <div>
                          <p className="at">{t("AT")} Miss API key</p>
                          <p className="name">{item?.name}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
                {data?.cart?.cartinfo?.items?.length > 5 && (
                  <div className="item_more">
                    + {data?.cart?.cartinfo?.items?.length - 4}
                  </div>
                )}
              </div>
              <div className="about_items">
                <p className="count">
                  {t("SELECTED_ITEM(S)")} {data?.cart?.cartinfo?.items?.length}
                </p>
                <p className="total">
                  {t("TOTAL")}: {data?.cart?.cartinfo?.total}{data?.cart?.cartinfo?.total_currency_symbol}
                </p>
              </div>
            </div>
            <Link href={"/cart"} type="submit" className="btn">
              Ar for Me <BtnArrowAnimation />
            </Link>
          </>
        )}
        {isMobile && (
          <div className="main_block">
            <div className="img_block">
              {data?.cart?.cartinfo?.items?.length === 1 ? (
                <Image
                  className="image"
                  height={1}
                  width={1}
                  layout="responsive"
                  alt={data?.cart?.cartinfo?.items?.name ||"products"}
                  objectFit="cover"
                  src={data?.cart?.cartinfo?.items?.[0]?.images?.[0]}
                />
              ) : (
                <div className="count">+ {data?.cart?.cartinfo?.items?.length}</div>
              )}
            </div>
            <div>
              <p className="total">
                {t("TOTAL")}: {data?.cart?.cartinfo?.total}
              </p>
              <Link href={"/cart"} type="submit" className="btn">
                Ar for Me <BtnArrowAnimation />
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
