import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "WHY_CHOOSE_US";

const initialState = {
    why_choose_us: {},
};

export const whyChooseUsThunk = createAsyncThunk(`${name}/why-choose-us`, async () =>
  logger({
    method: "POST",
    url: `${API_URL}/why-choose-us`,
  })
);

const whyChooseUsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(whyChooseUsThunk.fulfilled, (state, action) => {
      state.pricing = action.payload;
    });
  },
});

export const selectWhyChooseUsData = (state) => state.whyChooseUs.pricing;

export default whyChooseUsSlice.reducer;
