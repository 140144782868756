import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../api_url";
import logger from "../logger";

const name = "PLACE_ORDER";

const initialState = {
  currency_abbreviations: [],
  countries: [],
  partners: [],
};

export const getCurrencyAbbreviationsThunk = createAsyncThunk(
  `${name}/currency_abbreviations`,
  async () =>
    logger({
      method: "POST",
      url: `${API_URL}/currency_abbreviations`,
    })
);

export const getCountriesThunk = createAsyncThunk(
  `${name}/get-countries`,
  async () =>
    logger({
      method: "POST",
      url: `${API_URL}/get-partners-addresses`,
    })
);

export const getPartnersAddressesByCountryThunk = createAsyncThunk(
    `${name}/get-partners-addresses-by-country`,
    async (value) =>
      logger({
        method: "POST",
        url: `${API_URL}/get-partners-addresses-by-country`,
        body: value
      })
  );

  export const addProductThunk = createAsyncThunk(
    `${name}/add-product`,
    async (value) =>
      logger({
        method: "POST",
        url: `${API_URL}/add-product`,
        body: value
      })
  );
  export const updateProductThunk = createAsyncThunk(
    `${name}/update-product`,
    async (value) =>
      logger({
        method: "POST",
        url: `${API_URL}/update-product`,
        body: value
      })
  );

const placeOrderSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencyAbbreviationsThunk.fulfilled, (state, action) => {
        state.currency_abbreviations = action.payload;
      })
      .addCase(getCountriesThunk.fulfilled, (state, action) => {
        const uniqueCurrencies = {};
        state.countries = action.payload.data?.filter(obj => {
          if (!uniqueCurrencies[obj.country.iso2]) {
            uniqueCurrencies[obj.country.iso2] = true;
            
            return true;
          }
          return false;
        });
      })
      .addCase(getPartnersAddressesByCountryThunk.fulfilled, (state, action) => {
        state.partners = action.payload;
      })
  },
});

export const selectCurrencyAbbreviationsSettings =
 (state) => state.placeOrder.currency_abbreviations;
export const selectCountries = (state) => state.placeOrder.countries;
export const selectPartners = (state) => state.placeOrder.partners;
export const selectShippment = (state) => state.placeOrder.shippingMethods


export default placeOrderSlice.reducer;
