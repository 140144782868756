import React from 'react'

export default function NotificationsUnred() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 014 4h10.1a5.085 5.085 0 000 2H4v12h16V9.9c.383-.083.742-.2 1.075-.35.333-.15.642-.333.925-.55v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 20H4zm8-9l3.65-2.275c.233.217.488.404.763.562.275.159.562.296.862.413l-4.75 2.975c-.167.1-.342.15-.525.15s-.358-.05-.525-.15L4 8V6l8 5z"
          fill="#2b2b2b"
        />
        <path
          d="M16.875 7.125A2.893 2.893 0 0019 8c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0022 5c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0019 2c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0016 5c0 .833.292 1.542.875 2.125z"
          fill="#FF3026"
        />
      </g>
    </svg>
  )
}
