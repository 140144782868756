import Link from "next/link";
import React from "react";
import RegistredHeaderDetails from "./RegistredHeaderDetails";
import { useTranslation } from "react-i18next";

export default function MobileMenu({ menuItems, openMobileMenu, isLogedIn, setOpenMenu }) {
  const [t] = useTranslation();

  return (
    <>
      <div className="mobile_menu">
        {isLogedIn && <RegistredHeaderDetails setOpenMenu={setOpenMenu}/>}
        <div className={isLogedIn ? "mobile_menu__items isLogedIn_items" : "mobile_menu__items"}>
          {menuItems.map((item, index) => {
            return (
              <Link href={item.slug} key={index}>
                <span onClick={() => openMobileMenu()}> {item.title} </span>
              </Link>
            );
          })}
        </div>
        {!isLogedIn && (
          <div className="mobile_menu__btn">
            <Link href="/registration">
              <div className="get_started">{t("GET_STARTED")}</div>
            </Link>
            <Link href="/login">
              <div className="sign_in">{t("SIGN_IN")}</div>
            </Link>
          </div>
        )}

        {/* gradient bg */}
        <div className="mobile_menu__for_gradient"></div>
      </div>
    </>
  );
}
